import { notification, Button } from "antd";
import { useEffect } from "react";

let hasShowed = false;

const revezoneLink = "https://revezone.com";
const LOCAL_STORAGE_NEVER_SHOW_KEY = "never_show_upgrade_notice";
const LOCAL_STORAGE_UPGRADE_SHOW_TIMESTAME_KEY = "upgrade_show_timestamp";

const neverShow = localStorage.getItem(LOCAL_STORAGE_NEVER_SHOW_KEY) === "true";
const upgradeShowTimestamp = Number(
  localStorage.getItem(LOCAL_STORAGE_UPGRADE_SHOW_TIMESTAME_KEY) || "",
);

const expired = Date.now() - upgradeShowTimestamp > 2 * 24 * 60 * 60 * 1000;

export default function GlobalMessage() {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (neverShow || hasShowed || !expired) {
      return;
    }

    hasShowed = true;

    localStorage.setItem(
      LOCAL_STORAGE_UPGRADE_SHOW_TIMESTAME_KEY,
      String(Date.now()),
    );

    api.info({
      message: `升级提醒`,
      description: (
        <>
          <div style={{ marginBottom: 10 }}>
            本产品已全面升级至{" "}
            <a href={revezoneLink} target="_blank" rel="noreferrer">
              Revezone
            </a>
            ，支持更方便的文件管理和自定义字体。
          </div>
          <div>
            <Button
              size="small"
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => {
                window.open(revezoneLink);
              }}
            >
              立即体验
            </Button>
            <Button
              size="small"
              onClick={() => {
                localStorage.setItem(LOCAL_STORAGE_NEVER_SHOW_KEY, "true");
                api.destroy();
              }}
            >
              不再提醒
            </Button>
          </div>
        </>
      ),
    });
  }, [api]);

  return <>{contextHolder}</>;
}
